/** *************************************************************************************************
 * APPLICATION IMPORTS
 * This polyfill fixes the issue with the global object not being available in the browser for jbl-pip imports
 */
interface Window {
	global: Window;
	process: { env: { DEBUG: string | undefined } };
}
(window as Window).global = window;
(window as Window).process = {
	env: { DEBUG: undefined }
};
